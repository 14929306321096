import React from 'react'
import './Portfolio.css';

function Portfolio() {
    return (
        <section className='portfolio'>
            <div className='portfolio__container'>
                <div className='portfolio-links'>
                    <h4 className='portfolio-links__name'>Портфолио</h4>
                    <ul className='portfolio-links__items'>
                        <li className='portfolio-links__item'>
                            <a href='http://синай96.рф/'
                               className='portfolio-links__link' target='_blank' rel='noreferrer'>СИНАЙ96 — Строительная
                                компания</a>
                            <h4 className='portfolio-links__link-arrow'>&#8599;</h4>
                        </li>
                        <li className='portfolio-links__item'>
                            <a href='http://avtospa-nt.ru/'
                               className='portfolio-links__link' target='_blank' rel='noreferrer'>АвтоSPA — Детейлинг
                                центр</a>
                            <h4 className='portfolio-links__link-arrow'>&#8599;</h4>
                        </li>
                        <li className='portfolio-links__item'>
                            <a href='https://www.afisha-surguta.ru/'
                               className='portfolio-links__link' target='_blank' rel='noreferrer'>АФИША СУРГУТА — Афиша
                                мероприятий</a>
                            <h4 className='portfolio-links__link-arrow'>&#8599;</h4>
                        </li>
                        <li className='portfolio-links__item'>
                            <a href='https://evgeniigladun.github.io/release-how-to-learn/'
                               className='portfolio-links__link' target='_blank' rel='noreferrer'>Научиться учиться —
                                онлайн-школа</a>
                            <h4 className='portfolio-links__link-arrow'>&#8599;</h4>
                        </li>
                        <li className='portfolio-links__item'>
                            <a href='https://evgeniigladun.github.io/REACT-MESTO-AUTH/'
                               className='portfolio-links__link' target='_blank' rel='noreferrer'>Адаптивный сайт</a>
                            <h4 className='portfolio-links__link-arrow'>&#8599;</h4>
                        </li>
                        <li className='portfolio-links__item'>
                            <a href={false ? 'https://photograms.nomoredomainsclub.ru/' : '/'}
                               className='portfolio-links__link portfolio-links__link_disabled' target='_blank'
                               rel='noreferrer'>Одностраничное приложение</a>
                            <div
                                className='portfolio-links__circle-inner'>
                                <div className='portfolio-links__circle'></div>
                            </div>
                            <h4 className='portfolio-links__link-arrow portfolio-links__link-arrow_disabled'>&#8599;</h4>
                        </li>
                    </ul>
                    <p
                        className='portfolio-links__description'
                    >
                        ● Сайт недоступен
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Portfolio;